import React from 'react';

function App() {
  const pageStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    flexDirection: 'column',
    textAlign: 'center'
  };

  const headingStyles = {
    fontSize: '3rem',
    color: '#333',
  };

  const paragraphStyles = {
    fontSize: '1.5rem',
    color: '#777',
    marginTop: '1rem',
  };

  const iconStyles = {
    fontSize: '5rem',
    color: '#ffa500',
    marginBottom: '2rem',
  };

  return (
    <div style={pageStyles}>
      <div style={iconStyles}>🚧</div>
      <h1 style={headingStyles}>Under Construction</h1>
      <p style={paragraphStyles}>
        Our website is currently under construction. We’ll be here soon with our new awesome site.
      </p>
    </div>
  );
}

export default App;